import React from 'react';
import LicenseData from "../../assets/license/license.json"

const ListLicense = () => {
  return LicenseData.map((v) => {
    return (
      <div className="p-6">
        <p className="text-xs">{v.libraryName}</p>
        <p className="text-xs">{v._licenseContent}</p>
      </div>
    );
  });
}

const LicensePage = () => {
  return (
    <section>
      <ListLicense />
    </section>
  );
};

export default LicensePage
